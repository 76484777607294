import React from 'react';
import { connect } from 'react-redux';
import { withPage } from "../PageContainer";
import Selectors from '../Selectors';
import { d2s } from '../Utils/DateUtil';
import * as Widget from '../Components/Widget';

class ArchivePage extends React.Component {
  render() {
    let { item } = this.props;

    return (
      <Widget.Container>
        <Widget.MaxWidth width={720} extraCss="padding: 10px">
          <Widget.Center extraCss="color: #444;">
            <h2>{item.display_name}</h2>
          </Widget.Center>

          <Widget.Row justify="flex-end" extraCss="color: grey;">
            <p>{d2s(item.created)}</p>
          </Widget.Row>

          <Widget.Container extraCss=" margin-bottom: 10px; color: grey; text-align: justify; white-space: pre-wrap;">
            {item.description}
          </Widget.Container>

          {item.image && (
            <Widget.FixRatioImage
              extraStyle={{ marginBottom: 10 }}
              image={item.image}
            />
          )}

          <Widget.Row extraCss="img { max-width: 100% !important; height: auto !important;}">
            <div dangerouslySetInnerHTML={{ __html: item.detail_content }} />
          </Widget.Row>
        </Widget.MaxWidth>
      </Widget.Container>
    );
  }
}

export default withPage(
  connect(
    (state, ownProps) => ({
      item: Selectors.getItemById(state, ownProps.pageContext.itemId),
    }),
    null
  )(ArchivePage)
);
